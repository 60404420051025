import Link from "next/link"
import { HomepageBanners, Issues } from "../../../../lib/types"
import { getPermalink, PageType } from "../../../../lib/utils"
import FeaturedBanner from "../banner"
import { PaperType } from "../paper"
import HeaderDefault from "./default"
import HomeBanner from "./homeBanner"
import Subhead from "./subhead"
import VideoBG from "./videobg"
import { useRef, useState, useEffect } from "react"
import { useTheme } from "../theme"

export interface HeaderProps {
  special_issue?: boolean | null
  issue_number?: number
  title?: string
  type: PaperType
  banners?: HomepageBanners[]
  currentIssue?: Issues
}

const Header = (props: HeaderProps) => {
  const { title, type, banners, currentIssue } = props

  switch (type) {
    case PaperType.Homepage:
      return <HeaderHomepage title={title} type={type} banners={banners} currentIssue={currentIssue} />
    default:
      return <HeaderDefault title={title} type={type} />
  }
}

const getLocalStorageItem = (key: string): string | null => {
  return localStorage.getItem(key)
}

const setLocalStorageItem = (key: string, value: string) => {
  localStorage.setItem(key, value)
}

const removeLocalStorageItem = (key: string) => {
  localStorage.removeItem(key)
}

const HeaderHomepage = (props: HeaderProps) => {
  const { title, type, banners, currentIssue } = props
  const videoRef = useRef<HTMLVideoElement>(null)
  const [isPaused, setIsPaused] = useState(false)
  const { theme } = useTheme()
  const subheadFill = theme === "dark" ? "fill-white" : "fill-white"

  useEffect(() => {
    // Check if the localStorage item is already set for video pause
    const isVideoPaused = getLocalStorageItem("homepageVideoPaused")
    if (isVideoPaused === "true" && videoRef.current) {
      videoRef.current.pause()
      setIsPaused(true)
    }
  }, [])

  const handleVideoToggle = () => {
    if (videoRef.current) {
      if (isPaused) {
        videoRef.current.play()
        removeLocalStorageItem("homepageVideoPaused")
      } else {
        videoRef.current.pause()
        setLocalStorageItem("homepageVideoPaused", "true") // Set localStorage item
      }
      setIsPaused(!isPaused)
    }
  }

  const permalink = getPermalink({
    type: PageType.Home,
  })

  const play = (
    <svg width="21" height="21" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 5V19L19 12L8 5Z" fill="currentColor" />
    </svg>
  )

  const pause = (
    <svg width="21" height="21" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 5H9V19H6V5Z" fill="currentColor" />
      <path d="M15 5H18V19H15V5Z" fill="currentColor" />
    </svg>
  )

  return (
    <header className={`tablet:pt-0 relative rail-header-${type}`}>
      <div className="hidden">
        <h1>The Brooklyn Rail </h1>
        <h2>Critical Perspectives on Art, Politics and Culture</h2>
        {title && <h3>{title}</h3>}
      </div>

      <div className="relative h-[calc(100vh-26.5rem)] tablet-lg:h-[calc(100vh-20.5rem)]">
        <div className="absolute inset-0 bg-black bg-opacity-15 z-[1]" />
        <VideoBG videoRef={videoRef} />
        <div className="sticky top-0 z-[2]">
          <div className="p-3 pb-9 tablet:px-6">
            <Link href={permalink} className="w-full space-y-3">
              <HomeBanner />
              <Subhead fill={subheadFill} />
            </Link>
          </div>
        </div>
        <button
          onClick={handleVideoToggle}
          className="absolute font-sm bottom-3 right-3 bg-zinc-700 w-6 h-6 text-center rounded-full text-white z-10 flex justify-center items-center"
        >
          {isPaused ? play : pause}
        </button>
      </div>

      {banners && currentIssue && <FeaturedBanner currentIssue={currentIssue} />}
    </header>
  )
}

export default Header
